import {
  createEffect,
  createEvent,
  createStore,
  forward,
  guard
} from "effector";

export function createCountdown(
  name: string,
  // @ts-ignore
  { start, abort = createEvent(`${name}Reset`), timeout = 1000 }
) {
  // tick every 1 second
  const $working = createStore(true, { name: `${name}Working` });
  const tick = createEvent(`${name}Tick`);
  const timerFx = createEffect(`${name}Timer`).use(() => wait(timeout));

  $working.on(abort, () => false).on(start, () => true);

  guard({
    source: start,
    filter: timerFx.pending.map(is => !is),
    target: tick
  });

  forward({
    from: tick,
    to: timerFx
  });

  const willTick = guard({
    source: timerFx.done.map(({ params }: any) => params - 1),
    filter: seconds => seconds >= 0
  });

  guard({
    source: willTick,
    filter: $working,
    target: tick
  });

  return { tick };
}

function wait(ms: number) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}
