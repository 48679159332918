import React, { MutableRefObject, forwardRef } from "react";

import H5AudioPlayer from "react-h5-audio-player";

import { Box, IconButton, SxProps, Theme } from "@mui/material";

import PauseIcon from "~public/icons/Pause/Pause.svg";
import PlayIcon from "~public/icons/Play Icon/Play Icon.svg";

interface Props {
  audioSrc: string;
  sx?: SxProps<Theme>;
  contactPlayerRef: MutableRefObject<H5AudioPlayer | null>;
  welcomePlayerRef: MutableRefObject<H5AudioPlayer | null>;
  uber?: boolean;
  color?: string;
}

export const MapPlayer = forwardRef<H5AudioPlayer, Props>(function MapPlayer(
  { sx, audioSrc, welcomePlayerRef, contactPlayerRef, uber, color },
  ref
) {
  const stopOther = () => {
    contactPlayerRef.current?.audio.current?.pause();
    welcomePlayerRef.current?.audio.current?.pause();
  };

  return (
    <Box
      sx={{
        ...sx,
        "& .rhap_container": {
          backgroundColor: "transparent",
          boxShadow: "none"
        },
        "& .rhap_additional-controls": {
          display: "none"
        },
        "& .rhap_volume-controls": {
          display: "none"
        },
        "& .rhap_button-clear": {
          background: "transparent",
          outline: "none",
          border: "none",
          width: "auto",
          height: "auto"
        },
        "& .rhap_main-controls": {
          display: "flex",
          justifyContent: "center"
        },
        "& .rhap_main": {
          display: "flex",
          flexDirection: "column-reverse",
          alignItems: "center"
        },
        "& .rhap_progress-section": {
          width: "100%",
          position: "relative",
          transform: "translateY(23px)",
          display: "block"
        },
        "& .rhap_progress-indicator": {
          backgroundColor: color ? color : "#42210A81",
          height: "0",
          width: "auto"
        },
        "& .rhap_progress-bar": {
          height: "2px",
          width: "100%",
          position: "relative",
          backgroundColor: color ? color : "#42210A81"
        },
        "& .rhap_progress-filled": {
          height: "2px",
          backgroundColor: color ? color : uber ? "#000000" : "#42210A",
          position: "absolute",
          top: 0,
          "::after": {
            content: "''",
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            backgroundColor: color ? color : uber ? "#000000" : "#42210A",
            position: "absolute",
            right: 0,
            bottom: "50%",
            transform: "translateY(50%) translateX(100%)"
          }
        },
        "& .rhap_total-time": {
          position: "absolute",
          right: 0,
          top: 0
        }
      }}>
      <H5AudioPlayer
        src={audioSrc}
        ref={ref}
        showSkipControls={false}
        showJumpControls={false}
        customIcons={{
          play: (
            <IconButton
              onClick={stopOther}
              sx={{
                border: color
                  ? `2px solid ${color}`
                  : uber
                  ? "2px solid #000000"
                  : "2px solid #42210A",
                width: { xs: "100px", md: "123px" },
                height: { xs: "100px", md: "123px" },
                "& path": {
                  fill: color ? color : uber ? "#000000" : "#42210A"
                },
                "& .MuiButtonBase-root": {}
              }}>
              <Box
                sx={{
                  "& > svg": {
                    fontSize: { xs: "40px", md: "48px" }
                  }
                }}>
                <PlayIcon />
              </Box>
            </IconButton>
          ),
          pause: (
            <IconButton
              sx={{
                border: color
                  ? `2px solid ${color}`
                  : uber
                  ? "2px solid #000000"
                  : "2px solid #42210A",
                width: { xs: "100px", md: "123px" },
                height: { xs: "100px", md: "123px" },
                "& path": {
                  fill: color ? color : uber ? "#000000" : "#42210A"
                },
                "& .MuiButtonBase-root": {}
              }}>
              <Box
                sx={{
                  "& > svg": {
                    fontSize: { xs: "40px", md: "48px" }
                  }
                }}>
                <PauseIcon />
              </Box>
            </IconButton>
          )
        }}
      />
    </Box>
  );
});
