import React from "react";

import { WelcomeForm } from "@/api/Settings";

import { useEvent, useStore } from "effector-react";
import { FormikProps } from "formik";

import {
  $isWelcomeModalOpen,
  closeWelcomeModal
} from "@/components/blocks/play/welcome-modal/store";
import {
  Box,
  Button,
  Modal,
  SxProps,
  TextField,
  Theme,
  Typography
} from "@mui/material";

interface Props {
  sx?: SxProps<Theme>;
  formik: FormikProps<WelcomeForm>;
  uber?: boolean;
}

export const WelcomeModal = ({ sx, formik, uber }: Props) => {
  const isOpen = useStore($isWelcomeModalOpen);
  const closeModal = useEvent(closeWelcomeModal);

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Box
        sx={{
          ...sx,
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          top: "15%",
          left: "50%",
          transform: "translate(-50%, 0%)",
          backgroundColor: "#FFFFFF",
          padding: "10px",
          borderRadius: "10px",
          maxWidth: "550px",
          width: { xs: "calc(100% - 20px)" },
          maxHeight: "70%",
          overflow: "hidden"
        }}>
        <Typography>
          Email<span style={{ color: "red" }}>*</span>
        </Typography>
        <TextField
          type={"email"}
          value={formik.values.email}
          onChange={event => formik.setFieldValue("email", event.target.value)}
          sx={{ marginBottom: "40px", marginTop: 0 }}
        />
        <Typography>
          Name<span style={{ color: "red" }}>*</span>
        </Typography>
        <TextField
          value={formik.values.name}
          onChange={event => formik.setFieldValue("name", event.target.value)}
          sx={{ marginBottom: "40px", marginTop: 0 }}
        />
        <Button
          disabled={formik.isSubmitting || !formik.isValid}
          sx={{
            backgroundColor: uber ? "#000000 !important" : "#42210A !important",
            color: "#FFFFFF !important"
          }}
          onClick={handleSubmit}>
          Send
        </Button>
      </Box>
    </Modal>
  );
};
