import SettingsApi from "@/api/Settings";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";

enum Keys {
  ContactUs = "contactUs"
}

export const useStories = () => {
  const queryClient = useQueryClient();

  const useSendWelcomeForm = useMutation(
    (data: FormData) => SettingsApi.sendWelcomeForm(data),
    {
      onMutate: async data => {
        await queryClient.cancelQueries([Keys.ContactUs]);
        const prevProfileData = await queryClient.getQueryData([
          Keys.ContactUs
        ]);
        queryClient.setQueryData([Keys.ContactUs], data);
        return { prevProfileData };
      },
      onError: (error, variables, context) => {
        queryClient.setQueryData([""], context?.prevProfileData);
        toast.error("Can't send data. Check network connection");
      }
    }
  );

  return {
    useSendWelcomeForm
  } as const;
};
