import React, {
  MutableRefObject,
  forwardRef,
  useEffect,
  useState
} from "react";

import H5AudioPlayer from "react-h5-audio-player";

import {
  Box,
  CircularProgress,
  IconButton,
  SxProps,
  Theme
} from "@mui/material";

import PauseIcon from "~public/icons/Pause/Pause.svg";
import PlayIcon from "~public/icons/Play Icon/Play Icon.svg";

interface Props {
  audioSrc: string;
  place?: "top";
  sx?: SxProps<Theme>;
  mapPlayerRef: MutableRefObject<H5AudioPlayer | null>;
  contactPlayerRef: MutableRefObject<H5AudioPlayer | null>;
  color?: string;
}

export const WelcomePlayer = forwardRef<H5AudioPlayer, Props>(
  function WelcomePlayer(
    { audioSrc, place, sx, contactPlayerRef, mapPlayerRef, color },
    ref
  ) {
    const [currentValue, setCurrentValue] = useState(0);
    const stopOther = () => {
      contactPlayerRef.current?.audio.current?.pause();
      mapPlayerRef.current?.audio.current?.pause();
    };

    useEffect(() => {
      const element = document.querySelector(".rhap_progress-container");

      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          // @ts-ignore
          setCurrentValue(mutation.target.ariaValueNow as number);
        });
      });

      observer.observe(element as any, { attributes: true });
    }, []);

    return (
      <Box
        sx={{
          ...sx,
          position: "relative",
          "& .rhap_container": {
            backgroundColor: "transparent",
            display: "inherit",
            width: "auto",
            padding: 0,
            boxShadow: "none"
          },
          "& .rhap_play-pause-button": {
          },
          "& .rhap_additional-controls": {
            display: "none"
          },
          "& .rhap_volume-controls": {
            display: "none"
          },
          "& .rhap_button-clear": {
            background: "transparent",
            outline: "none",
            border: "none",
            width: "auto",
            height: "auto"
          },
          "& .rhap_main-controls": {
            display: "flex",
            justifyContent: "center"
          },
          "& .rhap_main": {
            display: "flex",
            flexDirection: "column-reverse",
            alignItems: "center"
          },
          "& .rhap_progress-section": {
            width: "100%",
            position: "relative",
            transform: "translateY(23px)",
            display: place === "top" ? "none" : "block"
          },
          "& .rhap_progress-indicator": {
            backgroundColor: "#C1E4FF",
            height: "2px",
            width: "auto"
          },
          "& .rhap_progress-bar": {
            height: "2px",
            width: "100%",
            position: "relative"
          },
          "& .rhap_progress-filled": {
            height: "2px",
            backgroundColor: "#42210A",
            position: "absolute",
            top: 0,
            "::after": {
              content: "''",
              width: "16px",
              height: "16px",
              borderRadius: "50%",
              backgroundColor: "#42210A",
              position: "absolute",
              right: 0,
              bottom: "50%",
              transform: "translateY(50%) translateX(100%)"
            }
          },
          "& .rhap_total-time": {
            position: "absolute",
            right: 0,
            top: 0
          }
        }}>
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "111px", md: "176px" },
            height: { xs: "111px", md: "176px" }
          }}>
          <CircularProgress
            sx={{
              "&.MuiCircularProgress-root": {
                color: color
                  ? color
                  : place === "top"
                    ? "#FFFFFF"
                    : "#42210A",
                width: { xs: "111px !important", md: "176px !important" },
                height: { xs: "111px !important", md: "176px !important" }
              }
              // position: "absolute",
              // left: "45%",
              // transform: `translateX(-50%)`
            }}
            disableShrink={false}
            thickness={2}
            variant={"determinate"}
            value={currentValue}
          />
        </Box>
        <H5AudioPlayer
          src={audioSrc}
          ref={ref}
          showSkipControls={false}
          showJumpControls={false}
          customIcons={{
            play: (
              <IconButton
                onClick={stopOther}
                sx={{
                  border: color
                    ? `2px solid ${color}`
                    : place === "top"
                    ? "2px solid #FFFFFF"
                    : "4px solid #42210A",
                  width: {
                    xs: "100px",
                    md: place === "top" ? "163px" : "123px"
                  },
                  height: {
                    xs: "100px",
                    md: place === "top" ? "163px" : "123px"
                  },
                  "& path": {
                    fill: color
                      ? color
                      : place === "top"
                      ? "#FFFFFF"
                      : "#42210A"
                  },
                  "& .MuiButtonBase-root": {}
                }}>
                <Box
                  sx={{
                    "& > svg": {
                      fontSize: { xs: "40px", md: "48px" }
                    }
                  }}>
                  <PlayIcon />
                </Box>
              </IconButton>
            ),
            pause: (
              <IconButton
                sx={{
                  border: color
                    ? `2px solid ${color}`
                    : place === "top"
                    ? "2px solid #FFFFFF"
                    : "4px solid #42210A",
                  width: {
                    xs: "100px",
                    md: place === "top" ? "163px" : "123px"
                  },
                  height: {
                    xs: "100px",
                    md: place === "top" ? "163px" : "123px"
                  },
                  "& path": {
                    fill: color
                      ? color
                      : place === "top"
                      ? "#FFFFFF"
                      : "#42210A"
                  },
                  "& .MuiButtonBase-root": {}
                }}>
                <Box
                  sx={{
                    "& > svg": {
                      fontSize: { xs: "40px", md: "48px" }
                    }
                  }}>
                  <PauseIcon />
                </Box>
              </IconButton>
            )
          }}
        />
      </Box>
    );
  }
);
