import { SettingsApiNS as NS } from "~types/api/settings";
import { Review } from "~types/review";

import http from "../utils/http";

export interface WelcomeForm {
  name: string;
  email: string;
  audio: string;
}

const SettingsApi = {
  getReviews: (params: NS.GetReviewsArgs) => {
    const objectId = params.object_id ?? 0;
    return http.get<NS.GetReviewsResponse>(
      `/social/review/${params.type}/${objectId}`,
      {
        params: {
          page_size: params.page_size,
          page: params.page
        }
      }
    );
  },

  sendWelcomeForm: (body: FormData) => {
    return http.post<NS.SendContactUsFormResponse>("/emailer/contact_us", body);
  },

  removeReview: (params: NS.RemoveReviewArgs) => {
    const objectId = params.object_id ?? 0;
    return http.delete<NS.RemoveReviewResponse>(
      `/social/review/${params.type}/${objectId}/${params.pk}`
    );
  },

  addReview: (params: NS.AddReviewArgs) => {
    const data: { rating: number; review_text: string; image?: string } = {
      rating: +params.rating,
      review_text: params.review_text
    };
    const formData = new FormData();
    formData.append("rating", +params.rating as any);
    formData.append("review_text", params.review_text);

    if (params.image) {
      data.image = params.image;
      formData.append("image", params.image);
    }

    return http.post<Review>(
      `/social/review/${params.type}/${params.object_id}`,
      formData
    );
  },

  sendContactUsForm: (body: NS.SendContactUsFormArgs) => {
    return http.post<NS.SendContactUsFormResponse>("/emailer/contact_us", body);
  }
};

export default SettingsApi;
