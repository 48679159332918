import { useEffect, useState } from "react";

import { createCountdown } from "@/utils/countdown";
import { createEvent } from "effector";

const requestRecorder = async () => {
  let stream = null;

  try {
    stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false
    });
  } catch (e) {
    alert("Give access to the microphone on your device");
  }

  if (stream) {
    return new MediaRecorder(stream);
  }
  return null;
};

const startCountdown = createEvent();
const abortCountdown = createEvent();

const countdown = createCountdown("simple", {
  start: startCountdown,
  abort: abortCountdown,
  timeout: 100
});

export const useRecorder = (maxCount: number) => {
  const [audioURL, setAudioURL] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState<MediaRecorder | null>(null);

  useEffect(() => {
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error);
      }
      return;
    }

    if (isRecording) {
      recorder.start();
      setAudioURL("");
      startCountdown(maxCount as any);
    } else {
      recorder.stop();
      setAudioURL("");
      abortCountdown();
    }

    const handleData = (event: BlobEvent) => {
      setAudioURL(URL.createObjectURL(event.data));
    };

    recorder.addEventListener("dataavailable", handleData);

    return () => {
      recorder.removeEventListener("dataavailable", handleData);
      abortCountdown();
    };
  }, [recorder, isRecording]);

  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  return [
    audioURL,
    isRecording,
    startRecording,
    stopRecording,
    countdown,
    setAudioURL
  ] as const;
};
